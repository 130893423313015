import { useForm } from "react-hook-form";
import { Button } from "./Button";

import { Card } from "flowbite-react";
import HomeService from "../services/homeService";
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";

import React from "react";
import { useModal } from "../context/ModalContext";

import { Flowbite } from "flowbite-react";
import { FaStarOfLife } from "react-icons/fa";
import { customTheme } from "../styles/customThemes";
const ContactUsForm = () => {
  const [token, setToken] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { openModal } = useModal();
  const onSubmit = async (data) => {
    await HomeService.sendMessage({
      ...data,
      token: token,
    });

    openModal("MessageModal", {});
  };

  const onChange = (value) => {
    setToken(value);
  };

  return (
    <div className="py-4 sm:py-6 lg:py-8 text-justify">
      <Flowbite theme={{ theme: customTheme }}>
        <Card className="w-full lg:p-10 p-4">
          <h3 className="text-center h3">Санал хүсэлт</h3>
          <form
            className="flex flex-col gap-4"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="mb-4">
              <label
                htmlFor="input"
                className="flex items-center block mb-2 text-md font-medium text-gray-900 "
              >
                <div className="mr-2">Нэр</div>{" "}
                <FaStarOfLife size={10} className="text-red-500" />
              </label>
              <input
                type="text"
                id="name-text"
                {...register("name", { required: true })}
                aria-describedby="helper-text-explanation"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-4"
                placeholder="Нэр"
              />
              {errors.name && (
                <span className="text-red-500">Та нэрээ оруулна уу</span>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor="input"
                className="block mb-2 text-md font-medium text-gray-900 "
              >
                Утас
              </label>
              <input
                type="text"
                id="phone-text"
                {...register("phone")}
                aria-describedby="helper-text-explanation"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-4"
                placeholder="Утас"
              />
              <span className="text-gray-500 text-sm">Заавал биш</span>
            </div>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block mb-2 text-md font-medium text-gray-900 "
              >
                Имэйл
              </label>
              <input
                type="email"
                id="email-text"
                {...register("email")}
                // aria-describedby="helper-text-explanation"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-4"
                placeholder="Имэйл"
              />
              <span className="text-gray-500 text-sm">Заавал биш</span>
            </div>
            <div className="mb-5">
              <label
                htmlFor="message"
                className="flex items-center block mb-2 text-md font-medium text-gray-900"
              >
                <div className="mr-2">Санал хүсэлт</div>
                <FaStarOfLife size={10} className="text-red-500" />
              </label>
              <textarea
                id="message"
                rows="5"
                {...register("message", { required: true })}
                className="block p-4 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-green-500 focus:border-green-500"
                placeholder="Санал хүсэлт..."
              ></textarea>
              {errors.message && (
                <span className="text-red-500">
                  Та санал хүсэлтээ үлдээнэ үү
                </span>
              )}
            </div>
            <ReCAPTCHA
              sitekey="6Ld996QpAAAAAF56nB9yZnqktL7EFFHpTgf-ee4r"
              onChange={onChange}
            />

            <Button disabled={token ? false : true} type="submit">
              Илгээх
            </Button>
          </form>
        </Card>
      </Flowbite>
    </div>
  );
};
export default ContactUsForm;
