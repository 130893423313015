const DecorImageSection = () => {
  const imgLink =
    "https://galba-bucket-public.s3.eu-central-1.amazonaws.com/entities/decor_image/639813ab-3121-4857-8e6a-d227a5fb3d04.jpg";
  return (
    <div>
      <img
        className="w-full max-h-[35rem] object-cover"
        src={imgLink}
        alt={imgLink}
      />
    </div>
  );
};

export default DecorImageSection;
