import Slider from "react-slick";
import MonitoringCard from "../components/MonitoringCard";

const MonitoringSection = () => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 625,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  return (
    <div className="py-4 sm:py-6 lg:py-8 text-justify">
      <h3 className="h3 text-center  p-0 sm:p-2 lg:p-4">
        Байгаль орчны хяналт шинжилгээний төрлүүд
      </h3>

      <Slider {...settings}>
        <MonitoringCard
          type={`water`}
          text={`Усны хяналт шинжилгээ`}
          href={`/monitoring/water-monitoring`}
        />
        <MonitoringCard
          type={`land`}
          text={`Бэлчээр, ургамлын хяналт шинжилгээ`}
          href={`/monitoring/land-monitoring`}
        />
        <MonitoringCard
          type={`animal`}
          text={`Зэрлэг амьтны ажиглалт`}
          href={`/monitoring/animal-monitoring`}
        />
        <MonitoringCard
          type={`dust`}
          text={`Агаарын чанар тоосжилтын хяналт шинжилгээ`}
          href={"/monitoring/dust-monitoring"}
        />
        <MonitoringCard
          type={`tailing`}
          text={`Уурхайн хаягдлын сан орчмын хяналт шинжилгээ`}
          href={"/monitoring/tailing-monitoring"}
        />
        <MonitoringCard
          type={`rehabilation`}
          text={`Нөхөн сэргээлт`}
          href={"/monitoring/rehabilation"}
        />
      </Slider>
    </div>
  );
};

export default MonitoringSection;
