import clsx from "clsx";
import { Link } from "react-router-dom";

const variantStyles = {
  primaryClassName: "text-white bg-green-500 hover:bg-green-600",
  secondaryClassName:
    "bg-white border border-green-500 hover:bg-gray-100 text-green-500",
  orangeClassName: "text-white bg-orange-500 hover:bg-orange-600",
  grayClassName: "text-white bg-gray-500 hover:bg-gray-600",
};

const sizeStyles = {
  smClassName: "px-5 py-2.5 text-base",
  lgClassName: "px-8 py-3.5 text-lg",
};

export function Button({
  variant = "primary",
  size = "lg",
  className,
  href,
  children,
  disabled = false,
  ...props
}) {
  className = clsx(
    "font-medium relative leading-normal inline-flex items-center justify-center duration-300 ease-in-out outline-none group",
    disabled ? "text-white bg-gray-200" : variantStyles[`${variant}ClassName`],
    sizeStyles[`${size}ClassName`],
    className
  );

  return href ? (
    <Link to={href} className={className} {...props}>
      {children}
    </Link>
  ) : (
    <button disabled={disabled} className={className} {...props}>
      {children}
    </button>
  );
}
