import { Link } from "react-router-dom";
import { IoWaterOutline, IoLeafOutline, IoPawOutline } from "react-icons/io5";
import { LuWind } from "react-icons/lu";
import { GiMining } from "react-icons/gi";
import { FaRecycle } from "react-icons/fa";

const MonitoringCard = ({ type, text, href }) => {
  return (
    <div className={`m-4 lg:m-8  group`} key={`slider-card-${type}`}>
      <Link to={href}>
        <div className="bg-white p-4 cursor-pointer group-hover:shadow-xl group-hover:-translate-y-1 transition-all duration-300  rounded-lg min-h-[15rem] lg:min-h-[20rem] flex flex-col w-full justify-center items-center">
          {type === "water" && (
            <div className="bg-blue-100 rounded-full text-center p-8">
              <IoWaterOutline size={70} className="text-blue-500 " />
            </div>
          )}
          {type === "land" && (
            <div className="bg-green-100 rounded-full text-center p-8">
              <IoLeafOutline size={70} className="text-green-500 " />
            </div>
          )}
          {type === "dust" && (
            <div className="bg-cyan-100 rounded-full text-center p-8">
              <LuWind size={70} className="text-cyan-500 " />
            </div>
          )}
          {type === "animal" && (
            <div className="bg-orange-100 rounded-full text-center p-8">
              <IoPawOutline size={70} className="text-orange-500 " />
            </div>
          )}
          {type === "tailing" && (
            <div className="bg-gray-100 rounded-full text-center p-8">
              <GiMining size={70} className="text-gray-500 " />
            </div>
          )}
          {type === "rehabilation" && (
            <div className="bg-violet-100 rounded-full text-center p-8">
              <FaRecycle size={70} className="text-violet-500" />
            </div>
          )}
          <div className="mt-4 text-center">
            <div className="h-[20px] w-full font-bold xl:text-lg text-md">
              {text}
            </div>
            {/* <div className=" w-full text-sm text-gray-400">{text}</div> */}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default MonitoringCard;
