import { Route, Routes } from "react-router-dom";

import { Navbar } from "./components/NavBar";
import { ModalProvider } from "./context/ModalContext";
import ModalManager from "./modals/ModalManager";
import FooterComponent from "./components/FooterComponent";
import React from "react";
import LazyLoad from "./components/LazyLoad";

import "reactflow/dist/style.css";

import HomePage from "./pages/HomePage";
const IntroductionPage = React.lazy(() => import("./pages/IntroductionPage"));
const CompanyStructurePage = React.lazy(() =>
  import("./pages/CompanyStructurePage")
);
const NewsPage = React.lazy(() => import("./pages/NewsPage"));
const ActivityReportPage = React.lazy(() =>
  import("./pages/ActivityReportPage")
);
const MagazinePage = React.lazy(() => import("./pages/MagazinePage"));
const WaterMonitoringPage = React.lazy(() =>
  import("./pages/WaterMonitoringPage")
);
const LandMonitoringPage = React.lazy(() =>
  import("./pages/LandMonitoringPage")
);
const DustMonitoringPage = React.lazy(() =>
  import("./pages/DustMonitoringPage")
);
const AnimalMonitoringPage = React.lazy(() =>
  import("./pages/AnimalMonitoringPage")
);
const TailingMonitoringPage = React.lazy(() =>
  import("./pages/TailingMonitoringPage")
);

const RehabilationPage = React.lazy(() => import("./pages/RehabilationPage"));

const NotFoundPage = React.lazy(() => import("./pages/NotFoundPage"));

const WorkplacePage = React.lazy(() => import("./pages/WorkPlacePage"));
const SelectedNewsPage = React.lazy(() => import("./pages/SelectedNewsPage"));
const SelectedJobOfferPage = React.lazy(() =>
  import("./pages/SelectedJobOfferPage")
);
const BoardOfDirectorsPage = React.lazy(() =>
  import("./pages/BoardOfDirectorsPage")
);
const AcademicCouncilPage = React.lazy(() =>
  import("./pages/AcademicCouncilPage")
);
const ExecutiveTeamPage = React.lazy(() => import("./pages/ExecutiveTeamPage"));

const GalleryPage = React.lazy(() => import("./pages/GalleryPage"));

const App = () => {
  return (
    <ModalProvider>
      <ModalManager />
      <div className="flex flex-col min-h-[100vh]">
        <>
          <Navbar />
          <section className="flex-1">
            <Routes>
              <Route
                index
                element={
                  <React.Suspense fallback={<LazyLoad />}>
                    <HomePage />
                  </React.Suspense>
                }
              />
              <Route path="about-us">
                <Route
                  path="introduction"
                  element={
                    <React.Suspense fallback={<LazyLoad />}>
                      <IntroductionPage />
                    </React.Suspense>
                  }
                />
                <Route
                  path="board-of-directors"
                  element={
                    <React.Suspense fallback={<LazyLoad />}>
                      <BoardOfDirectorsPage />
                    </React.Suspense>
                  }
                />
                <Route
                  path="academic-council"
                  element={
                    <React.Suspense fallback={<LazyLoad />}>
                      <AcademicCouncilPage />
                    </React.Suspense>
                  }
                />
                <Route
                  path="executive-team"
                  element={
                    <React.Suspense fallback={<LazyLoad />}>
                      <ExecutiveTeamPage />
                    </React.Suspense>
                  }
                />
                <Route
                  path="company-structure"
                  element={
                    <React.Suspense fallback={<LazyLoad />}>
                      <CompanyStructurePage />
                    </React.Suspense>
                  }
                />
              </Route>
              <Route path="media">
                <Route
                  path="news"
                  element={
                    <React.Suspense fallback={<LazyLoad />}>
                      <NewsPage />
                    </React.Suspense>
                  }
                />
                <Route
                  path="news/:newsId"
                  element={
                    <React.Suspense fallback={<LazyLoad />}>
                      <SelectedNewsPage />
                    </React.Suspense>
                  }
                />

                <Route
                  path="magazine"
                  element={
                    <React.Suspense fallback={<LazyLoad />}>
                      <MagazinePage />
                    </React.Suspense>
                  }
                />
                <Route
                  path="activity-report"
                  element={
                    <React.Suspense fallback={<LazyLoad />}>
                      <ActivityReportPage />
                    </React.Suspense>
                  }
                />
              </Route>
              <Route path="monitoring">
                <Route
                  path="water-monitoring"
                  element={
                    <React.Suspense fallback={<LazyLoad />}>
                      <WaterMonitoringPage />
                    </React.Suspense>
                  }
                />
                <Route
                  path="land-monitoring"
                  element={
                    <React.Suspense fallback={<LazyLoad />}>
                      <LandMonitoringPage />
                    </React.Suspense>
                  }
                />
                <Route
                  path="dust-monitoring"
                  element={
                    <React.Suspense fallback={<LazyLoad />}>
                      <DustMonitoringPage />
                    </React.Suspense>
                  }
                />
                <Route
                  path="animal-monitoring"
                  element={
                    <React.Suspense fallback={<LazyLoad />}>
                      <AnimalMonitoringPage />
                    </React.Suspense>
                  }
                />
                <Route
                  path="tailing-monitoring"
                  element={
                    <React.Suspense fallback={<LazyLoad />}>
                      <TailingMonitoringPage />
                    </React.Suspense>
                  }
                />
                <Route
                  path="rehabilation"
                  element={
                    <React.Suspense fallback={<LazyLoad />}>
                      <RehabilationPage />
                    </React.Suspense>
                  }
                />
              </Route>
              <Route
                path="workplace"
                element={
                  <React.Suspense fallback={<LazyLoad />}>
                    <WorkplacePage />
                  </React.Suspense>
                }
              />
              <Route
                path="gallery"
                element={
                  <React.Suspense fallback={<LazyLoad />}>
                    <GalleryPage />
                  </React.Suspense>
                }
              />
              <Route
                path="/workplace/:jobId"
                element={
                  <React.Suspense fallback={<LazyLoad />}>
                    <SelectedJobOfferPage />
                  </React.Suspense>
                }
              />
              <Route
                path="*"
                element={
                  <React.Suspense fallback={<LazyLoad />}>
                    <NotFoundPage />
                  </React.Suspense>
                }
              />
            </Routes>
          </section>
        </>
        <FooterComponent className={"flex-none"} />
      </div>
    </ModalProvider>
  );
};
export default App;
