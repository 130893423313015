import FetchClient from "../utils/fetchClient";

const WebService = {
  async healthCheck() {
    return await FetchClient.get("check-health");
  },

  async getFooter() {
    return await FetchClient.get("footer");
  },

  async getTail() {
    return await FetchClient.get("tail-data");
  },
};

export default WebService;
