const MottoComponent = () => {
  return (
    <div className="py-6 sm:py-8 lg:py-12 text-justify">
      <div className=" w-full text-center flex flex-col items-center justify-center">
        <img
          src="logo.png"
          alt="Мөнх ногоон галба лого"
          className="w-[10rem] mb-10"
        />
        <span>
          “Говь нутгийн уул ус, ургамал амьтны мөнхийн таатай орчныг өвлүүлэн
          үлдээхийг зорино”
        </span>
      </div>
    </div>
  );
};

export default MottoComponent;
