import { FaCalendar } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";

const DateCard = ({ createdAt, title }) => {
  return (
    <div className="border border-borderGrey rounded-full flex justify-center items-center w-fit py-1 px-3 gap-1 mt-5">
      <FaCalendar className="mr-2 text-green-400 " />{" "}
      <p className="text-xs lg:text-base">
        {title ? title : "Нийтэлсэн огноо"}
      </p>
      <GoDotFill className="text-gray-400 mx-2" />
      <p className="text-xs lg:text-base"> {createdAt}</p>
    </div>
  );
};
export default DateCard;
