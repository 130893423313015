import { Flowbite, Modal } from "flowbite-react";
import { customTheme } from "../styles/customThemes";
import { Button } from "../components/Button";
import { CiCircleCheck } from "react-icons/ci";

const MessageModal = ({ onClose }) => {
  return (
    <Flowbite theme={{ theme: customTheme }}>
      <Modal show={true} size={"xl"} onClose={onClose}>
        <Modal.Body className="p-12 flex flex-col align-center justify-center">
          <div className="flex  pb-5 justify-center">
            <CiCircleCheck size={100} className="text-green-500" />
          </div>
          <div className="h3 text-center pb-5 ">
            Таны мессеж амжилттай илгээгдлээ...
          </div>
          <Button className="mt-16" onClick={() => window.location.reload()}>
            Хаах
          </Button>
        </Modal.Body>
      </Modal>
    </Flowbite>
  );
};
export default MessageModal;
