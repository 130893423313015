import { useModal } from "../context/ModalContext";

import StaffModal from "./StaffModal";
import MessageModal from "./MessageModal";

const ModalLookUp = {
  StaffModal: StaffModal,
  MessageModal: MessageModal,
};

const ModalManager = () => {
  const { modal, closeModal } = useModal();

  if (!modal || !ModalLookUp[modal.name]) return null;

  const Modal = ModalLookUp[modal.name];

  return <Modal onClose={closeModal} {...modal.props} />;
};
export default ModalManager;
