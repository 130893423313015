import axios from "axios";

const FetchClient = {
  async get(url) {
    const response = await axios.get(`/api/custom/${url}`, {
      headers: {
        "api-key": "6bc6dc68-4044-4c3e-b7a2-e996bc040135",
      },
    });
    return await response.data;
  },
  async post(url, body) {
    const response = await axios.post(`/api/custom/${url}`, body, {
      headers: {
        "Content-Type": "application/json",
        "api-key": "6bc6dc68-4044-4c3e-b7a2-e996bc040135",
      },
    });
    return await response.data;
  },
};

export default FetchClient;
