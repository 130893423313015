import { useEffect, useRef, useState, lazy } from "react";
import MainSection from "../section/MainSection";
import HomeService from "../services/homeService";
import HighlightedNewsSection from "../section/HighlightedNewsSection";
import MonitoringSection from "../section/MonitoringSection";
import ContactUsForm from "../components/ContactUsForm";
import MottoComponent from "../components/MottoComponent";
import DecorImageSection from "../section/DecorImageSection";
import { useLocation } from "react-router-dom";

const CarouselComponent = lazy(() => import("../components/CarouselComponent"));
const HomePage = () => {
  const [data, setData] = useState();
  const contactUsRef = useRef(null);
  const location = useLocation();
  const fetchData = async () => {
    await HomeService.healthCheck();
    const response = await HomeService.getHomePageData();
    setData(response);
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (contactUsRef.current && location.hash === "#contact-us") {
      setTimeout(function () {
        contactUsRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 2);
    }
  }, [data, location.hash, location.href]);

  return (
    <>
      <CarouselComponent />
      <MainSection title={"Нүүр хуудас"}>
        <MonitoringSection />
        {data && data.highlightNews.length >= 3 && (
          <HighlightedNewsSection highLight={data.highlightNews} />
        )}
      </MainSection>
      <DecorImageSection />
      <MainSection>
        <div id="contact-us" ref={contactUsRef}>
          <ContactUsForm />
        </div>
        <MottoComponent />
      </MainSection>
    </>
  );
};

export default HomePage;
