import { Footer } from "flowbite-react";
import { BsFacebook, BsYoutube, BsInstagram } from "react-icons/bs";
import { Flowbite } from "flowbite-react";
import { customTheme } from "../styles/customThemes";
import { useEffect, useState } from "react";
import WebService from "../services/webService";
import CountUp from "react-countup";
import { IoPeopleSharp } from "react-icons/io5";

const FooterComponent = () => {
  const [footer, footerInfo] = useState();
  const fetchData = async () => {
    const result = await WebService.getFooter();
    footerInfo(result);
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Flowbite theme={{ theme: customTheme }}>
      {footer && (
        <Footer container>
          <section className="px-4 py-4 sm:px-6 lg:px-8 w-full">
            <div className="mx-auto max-w-3xl lg:max-w-screen-2xl">
              <div className="w-full justify-between lg:flex lg:justify-between lg:flex ">
                <div className="max-w-sm xl:text-lg md:text-md sm:text-sm  text-xs text-ellipsis overflow-hidden ">
                  <h3 className="h4 !font-semibold text-white">
                    Хандалтын тоо
                  </h3>

                  <div className="h4 flex items-center !font-normal my-8 text-white ">
                    <IoPeopleSharp className="mr-5" />
                    <CountUp duration={5} end={footer.viewCount} />
                  </div>
                </div>
                <div className="grid  grid-cols-2 gap-8 sm:mt-4 sm:grid-cols-3 sm:gap-6">
                  <div>
                    <Footer.Title title="Бидний тухай" />
                    <Footer.LinkGroup col className="!ml-0 !list-none">
                      <Footer.Link href="/about-us/introduction">
                        Танилцуулга
                      </Footer.Link>
                      <Footer.Link href="/about-us/board-of-directors">
                        Удирдах зөвлөл
                      </Footer.Link>
                      <Footer.Link href="/about-us/academic-council">
                        Эрдэмтдийн зөвлөх баг
                      </Footer.Link>
                      <Footer.Link href="/about-us/executive-team">
                        Гүйцэтгэх баг
                      </Footer.Link>
                      <Footer.Link href="/about-us/company-structure">
                        Бүтэц зохион байгуулалт
                      </Footer.Link>
                    </Footer.LinkGroup>
                  </div>
                  <div>
                    <Footer.Title title="Хяналт судалгаа" />

                    <Footer.LinkGroup col className="!list-none !ml-0">
                      <Footer.Link href="/monitoring/water-monitoring">
                        Усны хяналт шинжилгээ
                      </Footer.Link>
                      <Footer.Link href="/monitoring/land-monitoring">
                        Бэлчээр, ургамлын хяналт шинжилгээ
                      </Footer.Link>
                      <Footer.Link href="/monitoring/dust-monitoring">
                        Агаарын чанар, тоосжилтын хяналт шинжилгээ
                      </Footer.Link>
                      <Footer.Link href="/monitoring/animal-monitoring">
                        Зэрлэг амьтны ажиглалт
                      </Footer.Link>
                      <Footer.Link href="/monitoring/tailing-monitoring">
                        Уурхайн хаягдлын сан орчмын хяналт шинжилгээ
                      </Footer.Link>
                      <Footer.Link href="/monitoring/rehabilation">
                        Нөхөн сэргээлт
                      </Footer.Link>
                    </Footer.LinkGroup>
                  </div>
                  <div>
                    <Footer.Title title="Холбоо барих" />
                    <Footer.LinkGroup col className="!list-none !ml-0">
                      <Footer.Link href="/workplace">Ажлын байр</Footer.Link>
                      <div>Утас</div>
                      {footer.contactUs?.phone && (
                        <Footer.Title
                          className="!m-0"
                          title={footer.contactUs?.phone}
                        />
                      )}
                      <div>Мэйл</div>
                      {footer.contactUs?.mail && (
                        <Footer.Title
                          className="!m-0"
                          title={footer.contactUs.mail}
                        />
                      )}
                      <div>Хаяг</div>
                      {footer.contactUs?.location && (
                        <Footer.Title
                          className={`!m-0 
                        `}
                          title={footer.contactUs.location}
                        />
                      )}
                    </Footer.LinkGroup>
                  </div>
                </div>
              </div>
              <Footer.Divider />
              <div className="w-full sm:flex sm:items-center sm:justify-between">
                <Footer.Copyright
                  href="#"
                  by="Мөнх Ногоон Галба"
                  year={new Date().getFullYear()}
                />
                <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
                  {footer.contactUs?.facebook && (
                    <Footer.Icon
                      href={footer.contactUs?.facebook}
                      target="_"
                      icon={BsFacebook}
                    />
                  )}
                  {footer.contactUs?.youtube && (
                    <Footer.Icon
                      href={footer.contactUs.youtube}
                      target="_"
                      icon={BsYoutube}
                    />
                  )}
                  {footer.contactUs?.instagram && (
                    <Footer.Icon
                      href={footer.contactUs.instagram}
                      target="_"
                      icon={BsInstagram}
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
        </Footer>
      )}
    </Flowbite>
  );
};

export default FooterComponent;
