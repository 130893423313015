import SEO from "../components/Seo";

const MainSection = ({ children, title }) => {
  return (
    <div className="py-8">
      {title && <SEO title={title} />}

      <div className="px-4 py-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-3xl lg:max-w-screen-2xl">{children}</div>
      </div>
    </div>
  );
};
export default MainSection;
