import FetchClient from "../utils/fetchClient";

const HomeService = {
  async getHomePageData() {
    return await FetchClient.get("home-page-data");
  },
  async sendMessage({ name, email, phone, message, token }) {
    return await FetchClient.post("send-message", {
      name,
      message,
      phone,
      email,
      token,
    });
  },
  async healthCheck() {
    return await FetchClient.get("check-health");
  },
};

export default HomeService;
