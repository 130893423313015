import Slider from "react-slick";
import InfoCard from "../components/InfoCard";

const settings = {
  dots: true,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1536,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
  ],
};

const HighlightedNewsSection = ({ highLight }) => {
  return (
    <div className="py-4 sm:py-6 lg:py-8">
      <div className="flex items-center justify-center pb-4 sm:pb-6 lg:pb-8">
        <h3 className="h3 text-center mx-5">Цаг үеийн мэдээ</h3>
        <a
          href="/media/news"
          className="text-green-500 hover:underline hover:cursor-pointer"
        >{`Цааш үзэх >>`}</a>
      </div>
      <div className="bg-white p-4 sm:p-6 lg:p-8 rounded-lg">
        <Slider {...settings}>
          {highLight.map((news) => (
            <div className={`p-4 lg:p-8`} key={`slider-card-${news.id}`}>
              <InfoCard
                id={news.id}
                color="bg-gray-50"
                type={"news"}
                key={`news-key-${news.id}`}
                image={news.image}
                title={news.title}
                content={news.content}
                description={news.description}
                createdAt={news.createdAt}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default HighlightedNewsSection;
