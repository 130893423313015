import { Modal } from "flowbite-react";
import { useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { MdMail, MdPhone } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import { Flowbite } from "flowbite-react";
import { customTheme } from "../styles/customThemes";

const StaffModal = ({ onClose, array }) => {
  const [index, setIndex] = useState(0);

  const pervStaff = () => {
    if (index > 0) setIndex(index - 1);
  };
  const nextStaff = () => {
    if (index < array.length - 1) setIndex(index + 1);
  };

  return (
    <Flowbite theme={{ theme: customTheme }}>
      <Modal show={true} size={"7xl"} onClose={onClose}>
        <Modal.Header className="pb-0 border-none"></Modal.Header>
        <Modal.Body className="pt-0 px-12">
          <div className="gap-12  grid grid-cols-3">
            <div className="col-span-3 lg:col-span-1 aspect-h-4 aspect-w-3 ">
              <img
                src={array[index].image}
                className="object-cover"
                fill="true"
                sizes="(min-width: 1024px) 33vw, (min-width: 576px) 50vw, 100vw"
                alt={array[index].name}
              />
            </div>
            <div className="m-0 flex flex-col justify-center col-span-3 lg:col-span-2 relative">
              <div className="mb-10">
                <div className="h3">{array[index].name}</div>
                <div className="text-lg text-green-600">
                  {array[index].status}
                </div>
              </div>
              <div className="text-justify pb-5">{array[index].about}</div>
              <div className="flex gap-x-6 items-center">
                {array[index].phone && (
                  <div className="flex items-center">
                    <MdPhone size={20} className="text-green-500 mr-2" />
                    <div className="text-gray-400"> {array[index].phone} </div>
                  </div>
                )}
                {array[index].email && (
                  <div className="flex items-center">
                    <MdMail size={20} className="text-green-500 mr-2" />
                    <div className="text-gray-400"> {array[index].email}</div>
                  </div>
                )}
              </div>

              {array.length > 1 && (
                <div className="flex gap-x-2 absolute bottom-0 right-0">
                  <IoIosArrowBack
                    onClick={pervStaff}
                    size={40}
                    className={`p-2  border-1 border rounded-lg ${
                      index === 0
                        ? "text-gray-300"
                        : "text-gray-500 hover:bg-gray-100"
                    }`}
                  />
                  <IoIosArrowForward
                    onClick={nextStaff}
                    size={40}
                    className={`p-2 border-1 border rounded-lg  ${
                      array.length - 1 === index
                        ? "text-gray-300"
                        : "text-gray-500 hover:bg-gray-100"
                    }  `}
                  />
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          className={`p-0 ${array.length > 1 ? "pb-4" : "pb-6"} border-none`}
        >
          {array.length > 1 && (
            <div className="flex gap-x-2 w-full  flex justify-center">
              {array.map((info, i) => {
                return (
                  <GoDotFill
                    key={`dot-${i}-key`}
                    size={20}
                    className={`${
                      index === i ? "text-green-500" : "text-gray-300"
                    }`}
                  />
                );
              })}
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </Flowbite>
  );
};
export default StaffModal;
