import { Fragment } from "react";
import { Menu, Transition, Popover } from "@headlessui/react";
import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";
import { Button } from "./Button";
import { Icon } from "./Icon";

const navigations = [
  { label: "Нүүр хуудас", href: "/" },
  {
    label: "Бидний тухай",
    href: "about-us",
    subMenus: [
      {
        href: "introduction",
        label: "Танилцуулга",
      },
      {
        href: "board-of-directors",
        label: "Удирдах зөвлөл",
      },
      {
        href: "academic-council",
        label: "Эрдэмтдийн зөвлөх баг",
      },
      {
        href: "executive-team",
        label: "Гүйцэтгэх баг",
      },
      {
        href: "company-structure",
        label: "Бүтэц зохион байгуулалт",
      },
    ],
  },
  {
    label: "Хэвлэл мэдээлэл",
    href: "media",
    subMenus: [
      {
        href: "news",
        label: "Мэдээ",
      },
      {
        href: "magazine",
        label: "Сэтгүүл",
      },
      {
        href: "activity-report",
        label: "Үйл ажиллагааны тайлан",
      },
    ],
  },
  {
    label: "Үйл ажиллагааны чиглэл",
    href: "monitoring",
    subMenus: [
      {
        href: "water-monitoring",
        label: "Усны хяналт шинжилгээ",
      },
      {
        href: "land-monitoring",
        label: "Бэлчээр, ургамлын хяналт шинжилгээ",
      },
      {
        href: "animal-monitoring",
        label: "Зэрлэг амьтны ажиглалт",
      },
      {
        href: "dust-monitoring",
        label: "Агаарын чанар, тоосжилтын хяналт шинжилгээ",
      },

      {
        href: "tailing-monitoring",
        label: "Уурхайн хаягдлын сан орчмын хяналт шинжилгээ",
      },

      {
        href: "rehabilation",
        label: "Нөхөн сэргээлт",
      },
    ],
  },
  { label: "Зургийн сан", href: "/gallery" },
];

export function Navbar() {
  const location = useLocation();
  function MenuIcon({ open }) {
    return (
      <>
        <span
          className={clsx(
            "absolute block h-1 rotate-0 transform  opacity-100 transition-all duration-300 ease-in-out",
            open
              ? "left-1/2 top-2 w-0 bg-green-50 group-hover:bg-white"
              : "left-0 top-0 w-full bg-green-900 group-hover:bg-green-600"
          )}
        />
        <span
          className={clsx(
            "absolute left-0 top-2 block h-1 w-full transform  opacity-100 transition-all duration-300 ease-in-out group-hover:bg-green-600",
            open
              ? "rotate-45 bg-green-50 group-hover:bg-white"
              : "rotate-0 bg-green-900 group-hover:bg-green-600"
          )}
        />
        <span
          className={clsx(
            "absolute left-0 top-2 block h-1 w-full transform  opacity-100 transition-all duration-300 ease-in-out group-hover:bg-green-600",
            open
              ? "-rotate-45 bg-green-50 group-hover:bg-white"
              : "rotate-0 bg-green-900 group-hover:bg-green-600"
          )}
        />
        <span
          className={clsx(
            "absolute block h-1 rotate-0 transform opacity-100 transition-all duration-300 ease-in-out group-hover:bg-green-600",
            open
              ? "left-1/2 top-2 w-0 bg-green-50 group-hover:bg-white"
              : "left-0 top-4 w-full bg-green-900 group-hover:bg-green-600"
          )}
        />
      </>
    );
  }

  function MobileNav() {
    return (
      <div className="block lg:hidden">
        <Popover>
          <Popover.Button
            className="group relative z-50 h-5 w-6 rotate-0 transform cursor-pointer transition duration-500 ease-in-out focus:outline-none"
            aria-label="Toggle Navigation"
          >
            {({ open }) => <MenuIcon open={open} />}
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="duration-300 ease-out"
            enterFrom="opacity-0 -translate-y-full"
            enterTo="opacity-100 translate-y-0"
            leave="duration-200 ease-in"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-full"
          >
            <Popover.Panel
              as="div"
              className="absolute inset-x-0 top-0 z-40 w-screen overflow-y-scroll bg-gradient-to-tr from-green-600 to-green-600 px-4 py-16 sm:px-8"
            >
              <div className="flex h-full w-full flex-col items-center justify-center">
                <div className="mx-auto flex w-full flex-col items-center justify-evenly space-y-6">
                  {navigations.map((navigation) => (
                    <Fragment key={`mobile-link-${navigation.label}`}>
                      {!navigation.subMenus && (
                        <Link to={navigation.href}>
                          <div className="group relative p-0.5 sm:text-left text-center">
                            <span className="relative z-10 text-2xl font-medium text-gray-50 duration-300 ease-in-out group-hover:text-white">
                              {navigation.label}
                            </span>
                            <span className="absolute -left-1 -right-1 bottom-0 h-1 origin-bottom scale-x-0 transform bg-yellow-400 duration-300 ease-in-out group-hover:scale-x-100" />
                          </div>
                        </Link>
                      )}
                    </Fragment>
                  ))}

                  <Button href="/#contact-us">Санал хүсэлт</Button>
                </div>
                <hr className="my-8 w-full border-green-200 border-opacity-30 sm:my-10" />

                {navigations
                  .filter((navigation) => navigation.subMenus)
                  .map((filteredNav) => (
                    <div
                      className="mx-auto mb-8 w-full max-w-md"
                      key={`mobile-link-sub-${filteredNav.label}`}
                    >
                      <p className="text-center text-lg font-semibold uppercase tracking-wider text-gray-200 sm:text-left">
                        {filteredNav.label}
                      </p>
                      <div className="mt-4 grid justify-items-center gap-4 sm:grid-cols-2 sm:justify-items-start sm:gap-x-8">
                        {filteredNav.subMenus.map((subMenu, index) => (
                          <Link
                            to={`/${filteredNav.href}/${subMenu.href}`}
                            key={`mobile-dropdown-${subMenu.label}`}
                            className={clsx(
                              index % 2 === 1 && "sm:justify-self-end"
                            )}
                          >
                            <div className="group relative p-0.5 sm:text-left text-center">
                              <span className="relative z-10 text-xl font-extralight text-gray-100 duration-300 ease-in-out group-hover:text-white">
                                {subMenu.label}
                              </span>
                              <span className="absolute -left-1 -right-1 bottom-0 h-1 origin-bottom scale-x-0 transform  bg-yellow-400 duration-300 ease-in-out group-hover:scale-x-100" />
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      </div>
    );
  }

  return (
    <div className="px-3 bg-white z-[1001]">
      <nav className="mx-auto flex items-center py-3">
        <div className="flex w-full items-center justify-between">
          {/* Main navigation menu for large screens */}
          <div className="hidden items-center justify-between md:space-x-6 lg:flex lg:space-x-10">
            <div className="w-16 mx-2 flex-shrink-0 flex-grow-0">
              <Link to="/">
                <img src={"/logo.png"} alt="Галба" className="h-auto" />
              </Link>
            </div>
            {navigations.map((navigation) => (
              <Fragment key={`desktop-link-${navigation.label}`}>
                {navigation.subMenus ? (
                  <Menu as="div" className="relative">
                    {({ open }) => (
                      <>
                        <Menu.Button className="outline-none focus:outline-none">
                          <div className="group relative p-0.5 sm:text-left text-center">
                            <span
                              className={clsx(
                                "relative z-10 flex items-center text-lg font-medium duration-300 ease-in-out group-hover:text-gray-600",
                                open ? "text-gray-600" : "text-gray-700"
                              )}
                            >
                              {navigation.label}

                              <Icon
                                icon="chevronDown"
                                className={clsx(
                                  "h-4.5 ml-1.5 w-4.5 transform duration-300 ease-in-out",
                                  open && "rotate-180"
                                )}
                                stroke={2}
                              />
                            </span>
                            <span className="absolute -left-1 -right-1 bottom-0 h-1 origin-bottom scale-x-0 transform bg-green-400 duration-300 ease-in-out group-hover:scale-x-100" />
                          </div>
                        </Menu.Button>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-300"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-200"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute left-1/2 mt-3 w-screen max-w-xs -translate-x-1/2  border  bg-white p-4  outline-none focus:outline-none">
                            {navigation.subMenus.map((subMenu, index) => (
                              <Menu.Item
                                key={`desktop-dropdown-link-${subMenu.label}`}
                                as="div"
                              >
                                {({ close }) => (
                                  <>
                                    <Link
                                      to={`/${navigation.href}/${subMenu.href}`}
                                      className={clsx(
                                        "group block w-full py-4 sm:p-5",
                                        location.pathname ===
                                          `/${navigation.href}/${subMenu.href}`
                                          ? "bg-green-25"
                                          : "transition duration-200 ease-in-out hover:bg-green-25/60"
                                      )}
                                      onClick={close}
                                    >
                                      <div className="text-lg font-semibold text-gray-600">
                                        {subMenu.label}
                                      </div>
                                      <p className="mt-1 text-sm text-gray-800 opacity-90">
                                        {/* {program.data.dropdownDescription} */}
                                      </p>
                                    </Link>
                                    {index !==
                                      navigation.subMenus.length - 1 && (
                                      <>
                                        <hr className="my-1 border-green-200/30 sm:my-2" />
                                      </>
                                    )}
                                  </>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                ) : (
                  <Link to={navigation.href}>
                    <div className="group relative p-0.5 sm:text-left text-center">
                      <span
                        className={clsx(
                          "relative z-10 text-lg font-medium",
                          location.pathname === navigation.href
                            ? "text-gray-600"
                            : "text-gray-700 duration-300 ease-in-out group-hover:text-gray-600"
                        )}
                      >
                        {navigation.label}
                      </span>
                      <span
                        className={clsx(
                          "absolute -left-1 -right-1 bottom-0 h-1 origin-bottom scale-x-0 transform  bg-green-400",
                          location.pathname === navigation.href
                            ? "scale-x-100"
                            : "duration-300 ease-in-out group-hover:scale-x-100"
                        )}
                      />
                    </div>
                  </Link>
                )}
              </Fragment>
            ))}
          </div>

          {/* Call to action button */}
          <div className="hidden lg:block">
            <Button href="/#contact-us">Санал хүсэлт</Button>
          </div>
          {/* Logo on smaller screens: < lg */}
          <div className="block w-14 flex-shrink-0 flex-grow-0 sm:w-16 lg:hidden">
            <Link to="/">
              <img src={"/logo.png"} alt="Bright" className="h-auto" />
            </Link>
          </div>

          <MobileNav />
        </div>
      </nav>
    </div>
  );
}
