import { Card } from "flowbite-react";
import { Flowbite } from "flowbite-react";
import { Link } from "react-router-dom";
import DateCard from "./DateCard";
const newsAspect = "[4/2]";
const magazineAspect = "[2/3]";
const customTheme = (type, color) => {
  return {
    card: {
      root: {
        base: `flex rounded-lg ${color} cursor-pointer group-hover:${color} group-hover:shadow-xl group-hover:-translate-y-1 transition-all duration-300  group-hover:[&>img]:brightness-50`,
        children: "flex h-full flex-col justify-center gap-4 p-6",
        horizontal: {
          off: "flex-col",
          on: "flex-col md:max-w-xl md:flex-row",
        },
        href: "hover:bg-gray-100 ",
      },
      img: {
        base: `mx-4 mt-4 rounded-lg object-cover  aspect-${
          type === "news" ? newsAspect : magazineAspect
        }`,
        horizontal: {
          off: "rounded-t-lg",
          on: "h-96 w-full rounded-t-lg object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg",
        },
      },
    },
  };
};

const InfoCard = ({
  type,
  id,
  image,
  title,
  createdAt,
  color = "bg-white",
  content,
  description,
}) => {
  return (
    <Link
      to={type === "news" ? `/media/news/${id}` : content}
      target={type === "news" ? "_self" : "_blank"}
      className="group"
    >
      <Flowbite theme={{ theme: customTheme(type, color) }}>
        <Card
          imgAlt="Meaningful alt text for an image that is not purely decorative"
          imgSrc={image}
        >
          <div className=" xl:h-20 md:h-16 h-12">
            <div className="font-semibold  text-gray-900 h5 text-ellipsis overflow-hidden xl:h-14 md:h-12 h-8">
              {title}
            </div>
            <p className="text-gray-500 xl:text-lg md:text-md text-sm text-ellipsis overflow-hidden xl:h-14 md:h-12 h-8">
              {description}
            </p>
          </div>
          <DateCard createdAt={createdAt} />
        </Card>
      </Flowbite>
    </Link>
  );
};

export default InfoCard;
